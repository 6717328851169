import {REREmployeeStatus} from "@/constants/request/RERConstants";
import {ResidencyStatus} from "@/constants/PersonConstants";
import FileMetaDTO from "@/dto/files/FileMetaDTO";

export class PublicREREmployeeDTO {

    id!: number;
    status!: REREmployeeStatus;
    personId: number | null = null;
    companyName!: string;
    companyId!: number;
    name!: string;
    actualizationOnly!: boolean;
    residencyStatus: ResidencyStatus | null = null;
    ssnImage: FileMetaDTO | null = null;
    eadFrontImage: FileMetaDTO | null = null;
    eadBackImage: FileMetaDTO | null = null;
    greenCardFrontImage: FileMetaDTO | null = null;
    greenCardBackImage: FileMetaDTO | null = null;
    citizenPassportImage: FileMetaDTO | null = null;
    stateIdFrontImage: FileMetaDTO | null = null;
    stateIdBackImage: FileMetaDTO | null = null;
    saveWithoutStateIdBack = false;
    voidCheckImage: FileMetaDTO | null = null;
    preparedI9: FileMetaDTO | null = null;
    signedI9: FileMetaDTO | null = null;
    employmentStart!: Date;
    employeeId: number | null = null;

    constructor(init?: Partial<PublicREREmployeeDTO>) {
        Object.assign(this, init);
    }
}
